import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-360',
  templateUrl: './full-360.component.html',
  styleUrls: ['./full-360.component.css']
})
export class Full360Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
